import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import Cookies from 'js-cookie';
import { tokenState } from '../atoms/authState';
import { Navigate } from 'react-router-dom';

export function Logout() {
  const setToken = useSetRecoilState(tokenState);

  useEffect(() => {
    setToken('');
    Cookies.remove('token');
  }, []);

  return (
    <Navigate to="/" />
  );
}
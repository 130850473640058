import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { tokenState } from '../atoms/authState';
import AdminForm from '../components/AdminForm';

const { API_HOST } = process.env;

export default function AdminCreatePage() {
  const token = useRecoilValue(tokenState);
  const navigate = useNavigate();

  const handleSubmit = async (formData) => {
    const res = await fetch(`${API_HOST}/v1/admin/hike`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(formData)
    });

    const resData = await res.json();
    const id = resData.id;
    navigate(`/admin/hike/${id}`);
  };

  return (
    <main>
      <h1 className="Title">Create New Hike</h1>
      <AdminForm
        onSubmit={handleSubmit} />
    </main>
  )
};

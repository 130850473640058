.Error {
  border-radius: 0.4rem;
  background-color: #8c0f08;
  color: #ffffff;

  padding: 1rem;
}

.Confirmation {
  border-radius: 0.4rem;
  background-color: #3d9970;
  color: #ffffff;

  padding: 1rem;
}
.Form {
  margin-top: 1.5rem;
}

.Row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
}

.Label {
  flex: 0 0 8rem;
}

@media (max-width: 500px) {
  .Label {
    flex: 0 0 5rem;
    margin-right: 0.5rem;
    font-size: 0.8rem;
  }
}

.LabelTop {
  align-self: flex-start;
}

.DatePicker {
  font-family: sans-serif;
  padding: 0.2rem 0.5rem;
  width: 12rem;
}

.Text {
  font-family: sans-serif;
  padding: 0.2rem 0.5rem;
  width: 12rem;
}

.TextArea {
  font-family: sans-serif;
  padding: 0.2rem 0.5rem;
  width: 12rem;
  height: 5rem;
}

.Divider {
  border: none;
  border-top: 1px solid black;
  width: 100%;
  margin: 0.5rem 0;
}

.StatusDropdown {
  width: 12rem;
  padding: 0.2rem 0.5rem;
}

@media (max-width: 500px) {
  .DatePicker, .Text, .TextArea, .StatusDropdown {
    width: 100%;
  }
}


input[type=submit].Submit, .Complete, .ShareButton {
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  border-radius: 0.2rem;
  background-color: #0074d9;
  color: #ffffff;

  margin-top: 1rem;
}

input[type=submit].Submit:hover, input[type=submit].Submit:active {
  background-color: #00539b;
}

.Complete {
  background-color: #ce0d03;
  margin-left: 2rem;
}

.Complete:hover, .Complete:active {
  background-color: #8c0f08;
}

.Preview {
  margin-top: 1rem;
  width: 100%;
  max-width: 65rem;
  height: 30rem;
}

.ShareButton {
  margin-left: 2rem;
  background-color: #27ae60;
}

.ShareButton:hover, .ShareButton.active {
  background-color: #218b4d;
}
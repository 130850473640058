.Row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
}

.Label {
  flex: 0 0 8rem;
}

.Password {
  font-family: sans-serif;
  padding: 0.2rem;
  width: 12rem;
}

input[type=submit].Submit {
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  border-radius: 0.2rem;
  background-color: #0074d9;
  color: #ffffff;
}

input[type=submit].Submit:hover, input[type=submit].Submit:active {
  background-color: #00539b;
}

.Error {
  border-radius: 0.4rem;
  background-color: #8c0f08;
  color: #ffffff;

  padding: 1rem;
}
import { useEffect, useState, useRef, Fragment } from 'react';
import { showDuration } from '../utils/showDuration';
import { CANCELED, COMPLETED } from '../constants/status';
import CalTopo from './CalTopo';
import GPXViewer from './GPXViewer';
import * as styles from './HikeViewer.module.css';

export default function HikeViewer({
  data,
  refetch
}) {
  const [ remainingTime, setRemainingTime ] = useState('');
  const [ isOverdue, setIsOverdue ] = useState(false);
  const timerRef = useRef();
  const prevOverdueRef = useRef();
  const refetchTimerRef = useRef();

  const startTime = new Date(data.startTime);
  const endTime = new Date(data.endTime);

  const isComplete = data?.status === COMPLETED;

  if (data.status === CANCELED) {
    return (
      <div className={styles.Status}>
        This hike has been canceled.
      </div>
    )
  }

  let map = data.calTopo
    ? (
      <CalTopo
        url={data.calTopo} />
    ) : null;
  if (!map && data.gpx) {
    map = (
      <GPXViewer
        data={data.gpx} />
    );
  }

  const getRemainingTime = () => {
    const now = new Date();
    if (isComplete) {
      return `Checked in at ${new Date(data.completed).toLocaleString()}`;
    }

    if (Math.abs(endTime.getTime() - now.getTime()) <= 60000) {
      return 'Due now';
    }

    if (startTime.getTime() > Date.now()) {
      return 'Not yet started';
    }

    const overdue = Date.now() > endTime;
    const duration = showDuration(new Date(), endTime, {
      allowNegative: true,
      showSeconds: false
    });
    const direction = overdue ? 'overdue' : 'remaining';
    return `${duration} ${direction}`;
  };

  const updateOverdue = (value) => {
    if (value && isComplete) {
      // not overdue
      setIsOverdue(false);
      return;
    }
    setIsOverdue(value);
  }

  useEffect(() => {
    // update remaining time every minute
    timerRef.current = setInterval(() => {
      setRemainingTime(getRemainingTime());
      updateOverdue(Date.now() > endTime.getTime());
    }, 60000);

    // set it immediately
    setRemainingTime(getRemainingTime());
    const overdueValue = !isComplete && Date.now() > endTime.getTime();
    updateOverdue(overdueValue);
    prevOverdueRef.current = overdueValue;

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    }
  }, [data.endTime]);

  useEffect(() => {
    if (isOverdue === prevOverdueRef.current) {
      // overdue value did not change
      return;
    }
    
    prevOverdueRef.current = isOverdue;

    if (isOverdue) {
      // we are flipping from not overdue to overdue, start getting new data every 5 minutes
      refetchTimerRef.current = setInterval(() => {
        refetch(true);
      }, 5 * 60 * 1000);

      // also do it immediately
      refetch(true);

      return () => {
        // cancel timers on unmount
        if (refetchTimerRef.current) {
          clearInterval(refetchTimerRef.current);
        }
      };
    }
    else {
      // we are flipping from overdue to not overdue, cancel any existing 5 minute timers
      if (refetchTimerRef.current) {
        clearInterval(refetchTimerRef.current);
        refetchTimerRef.current = undefined;
      }
    }
  }, [isOverdue]);

  let overdueHeader = null;
  let checkInStyle = styles.Row;
  if (isOverdue) {
    overdueHeader = (
      <div className={styles.OverdueHeader}>
        Overdue
      </div>
    );

    checkInStyle = [styles.Row, styles.RowDanger].join(' ');
  }

  let actualDuration = null;
  let checkedInHeader = null;
  if (isComplete) {
    actualDuration = (
      <Fragment>
        <br />{showDuration(startTime, new Date(data.completed), { showSeconds: false })} actual
      </Fragment>
    );

    checkedInHeader = (
      <div className={styles.CompletedHeader}>
        Checked in at {new Date(data.completed).toLocaleString()}
      </div>
    );
  }

  return (
    <div>
      { overdueHeader }
      { checkedInHeader }
      <div className={
        [styles.Row, styles.RowTop].join(' ')
      }>
        <div className={styles.Label}>
          Planned Start
        </div>
        <div className={styles.Value}>
          { startTime.toLocaleString() }
        </div>
      </div>
      <div className={checkInStyle}>
        <div className={styles.Label}>
          Return/Check In By
        </div>
        <div className={styles.Value}>
          { endTime.toLocaleString() }
          &nbsp; ({ remainingTime })
        </div>
      </div>
      <div className={styles.Row}>
        <div className={styles.Label}>
          Duration
        </div>
        <div className={styles.Value}>
          { showDuration(startTime, endTime) }
          { actualDuration }
        </div>
      </div>
      <div className={styles.Row}>
        <div className={styles.Label}>
          Notes
        </div>
        <div className={styles.Value}>
          { data.notes || '(No notes)' }
        </div>
      </div>

      <h2 className={styles.SecondHeader}>Planned Route</h2>
      <div className={styles.Map}>
        { map }
      </div>
    </div>
  )
}
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import * as styles from './Detail.module.css';
import { tokenState } from '../atoms/authState';
import AdminForm from '../components/AdminForm';

const { API_HOST } = process.env;

const fetchData = async (hikeId, token) => {
  const apiUrl = `${API_HOST}/v1/hike/${hikeId}`;

  const res = await fetch(apiUrl, {
    headers: {
      Authorization: token
    }
  });
  const data = await res.json();
  if (res.status !== 200) {
    throw new Error(data?.error || 'Could not load data');
  }
  return data;
};

export default function AdminEditPage() {
  const params = useParams();
  const hikeId = params?.hikeId;

  const token = useRecoilValue(tokenState);

  const [ isLoading, setLoading ] = useState(true);
  const [ hikeData, setHikeData ] = useState();
  const [ confirmation, setConfirmation ] = useState('');
  const [ error, setError ] = useState();

  useEffect(() => {
    if (hikeId) {
      (async () => {
        setLoading(true);
        try {
          const data = await fetchData(hikeId, token);
          setHikeData(data || null);
          setError(null);
        } catch (err) {
          console.error(err);
          setError(err.message || 'Something went wrong.');
          setConfirmation('');
          setHikeData(null);
        }
        setLoading(false);
      })();
    }
  }, [hikeId]);

  const handleSubmit = async (formData) => {
    try {
      const res = await fetch(`${API_HOST}/v1/admin/hike/${hikeId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        },
        body: JSON.stringify(formData)
      });

      const resData = await res.json();
      setHikeData(resData);
      setConfirmation(`Saved at ${new Date().toLocaleTimeString()}`);
      setError(null);
    } catch (err) {
      setError(err.message || 'Something went wrong.');
      setConfirmation('');
    }
  }

  const loading = isLoading
    ? (
      <div>Loading...</div>
    )
    : null;

  const content = !isLoading && hikeData
    ? (
      <AdminForm
        data={hikeData}
        onSubmit={handleSubmit}
        allowPastStart />
    )
    : null;

  const title = hikeData?.title || 'Hike';

  const errorMessage = error
    ? (
      <div className={styles.Error}>
        { error }
      </div>
    ) : null;

  const confirmationMessage = confirmation
    ? (
      <div className={styles.Confirmation}>
        { confirmation }
      </div>
    ) : null;

  return (
    <main>
      <h1>Edit {title}</h1>
      { loading }
      { confirmationMessage }
      { errorMessage }
      { content }
    </main>
  )
};

import { useEffect, useRef, useId } from 'react';
import L from 'leaflet';
import * as styles from './GPXViewer.module.css';

export default function GPXViewer({
  data
}) {
  const mapId = useId();
  const mapRef = useRef();
  const gpxLayer= useRef();

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map(mapId);
      L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
        maxZoom: 17,
        attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
      }).addTo(mapRef.current);
    }    
  }, [mapRef.current]);

  useEffect(() => {
    if (!mapRef.current || !data) {
      return;
    }

    if (gpxLayer.current) {
      // clear any existing layers from the map
      mapRef.current.removeLayer(gpxLayer.current);
    }

    const parsedData = JSON.parse(data);
    const mapLayer = L.geoJSON(parsedData, {
      style: {
        color: '#ff0000'
      },
      filter: (feature) => {
        return feature.geometry?.type === 'LineString'
      }
    });

    mapLayer.addTo(mapRef.current);
    mapRef.current.fitBounds(mapLayer.getBounds());

    gpxLayer.current = mapLayer;

  }, [mapRef.current, data]);

  return (
    <div
      id={mapId}
      className={styles.Map} />
  )
}

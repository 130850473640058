import * as styles from './CalTopo.module.css';

export default function CalTopo({
  url
}) {
  return (
    <iframe
      className={styles.Embed}
      src={url} />
  )
};

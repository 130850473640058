import { atom, selector } from 'recoil';

export const tokenState = atom({
  key: 'authToken',
  default: ''
});

export const accountTypeState = atom({
  key: 'accountType',
  default: ''
});

export const isAuthed = selector({
  key: 'isAuthed',
  get: ({get}) => !!get(tokenState)
});

export const isAdmin = selector({
  key: 'isAdmin',
  get: ({get}) => get(accountTypeState) === 'admin'
});

import { useState, useId } from 'react';
import * as styles from './Login.module.css';

function Login({ login, error }) {
  const [password, setPassword] = useState('');

  const performLogin = (e) => {
    e.preventDefault();

    login(password);
  };

  const errorMessage = error
    ? (
      <div className={styles.Error}>
        { error }
      </div>
    ): null;

  const passwordId = useId();

  return (
    <main>
      <h1 className="Title">Restricted Access</h1>
      <form onSubmit={performLogin}>
        <div className={styles.Row}>
          <label
            className={styles.Label}
            htmlFor={passwordId}>
              Password:
          </label>
          <input
            id={passwordId}
            className={styles.Password}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className={styles.Row}>
          <input
            className={styles.Submit}
            type="submit"
            value="Log In" />
        </div>
      </form>

      { errorMessage }
    </main>
  );
}

export default Login;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { tokenState } from '../atoms/authState';
import HikeViewer from '../components/HikeViewer';

import * as styles from './Detail.module.css';


const { API_HOST } = process.env;

const fetchData = async (hikeId, token) => {
  const apiUrl = `${API_HOST}/v1/hike/${hikeId}`;

  const res = await fetch(apiUrl, {
    headers: {
      Authorization: token
    }
  });
  const data = await res.json();
  if (res.status !== 200) {
    throw new Error(data?.error || 'Could not load data');
  }
  return data;
};

function DetailPage() {
  const params = useParams();
  const hikeId = params?.hikeId;

  const token = useRecoilValue(tokenState);

  const [ isLoading, setLoading ] = useState(true);
  const [ hikeData, setHikeData ] = useState();
  const [ error, setError ] = useState();

  const refetch = async (isSilent = false) => {
    if (!isSilent) {
      setLoading(true);
    }
    try {
      const data = await fetchData(hikeId, token);
      setHikeData(data || null);
      setError(null);
    } catch (err) {
      console.error(err);
      setError(err.message || 'Something went wrong.');
      setHikeData(null);
    }

    if (!isSilent) {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (hikeId) {
      (async () => {
        await refetch(false);
      })();
    }
  }, [hikeId]);

  const loading = isLoading
    ? (
      <div>Loading...</div>
    )
    : null;

  const content = !isLoading && hikeData
    ? (
      <HikeViewer
        data={hikeData}
        refetch={refetch} />
    )
    : null;

  const errorMessage = error
    ? (
      <div className={styles.Error}>
        { error }
      </div>
    ) : null;

  const title = hikeData?.title || 'Hike Information';

  return (
    <main>
      <h1 className="Title">{title}</h1>
      { loading }
      { errorMessage }
      { content }
    </main>
  );
}

export default DetailPage;

.SecondHeader {
  padding: 0;
  font-size: 1.2rem;
}

.Row {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  border-bottom: 1px solid black;
}

.RowTop {
  border-top: 1px solid black;
}

.RowDanger {
  background-color: #dc332a;
  color: #ffffff;
}

.Label {
  padding: 0.5rem;
  border-left: 1px solid black;
  min-width: 30%;
  max-width: 30rem;
}


.Value {
  flex: 1 1 auto;
  padding: 0.5rem;
  border-right: 1px solid black;
  border-left: 1px solid black;
}

@media (max-width: 500px) {
  .Label {
    flex: 0 0 30%;
    min-width: 5rem;
    font-size: 0.85rem;
  }

  .Value {
    font-size: 0.85rem;
  }
}

.Map {
  height: 30rem;
  width: 100%;
  max-width: 50rem;
}

.OverdueHeader {
  font-weight: bold;
  color: #dc332a;
  font-size: 1.75rem;
  text-transform: uppercase;
  margin: 1.5rem 0;
}

.CompletedHeader {
  border-radius: 0.4rem;
  background-color: #3d9970;
  color: #ffffff;

  padding: 1rem;
  margin: 1.5rem 0;
}

.Status {
  font-weight: bold;
}
export const CANCELED = 'canceled';
export const COMPLETED = 'completed';

export const STATUS_TYPES = [
  {
    value: 'normal',
    label: 'As planned'
  },
  {
    value: CANCELED,
    label: 'Canceled'
  },
  {
    value: COMPLETED,
    label: 'Completed'
  }
];

import { useState, useId, useRef, useEffect, Fragment } from 'react';
import * as styles from './AdminForm.module.css';
import CalTopo from './CalTopo';
import GPXUploader from './GPXUploader';
import GPXViewer from './GPXViewer';
import { STATUS_TYPES } from '../constants/status';
import { showDuration } from '../utils/showDuration';

function padNum(num, length, padding) {
  return num.toString().padStart(length, padding);
}

function dateToFormString(date) {
  const year = date.getFullYear();
  const month = padNum(date.getMonth() + 1, 2, '0');
  const day = padNum(date.getDate(), 2, '0');
  const hour = padNum(date.getHours(), 2, '0');
  const min = padNum(date.getMinutes(), 2, '0');

  return `${year}-${month}-${day}T${hour}:${min}`;
}

function getRecommendedEnd(date) {
  // add 4 hours
  return new Date(date.getTime() + (1000 * 60 * 60 * 4));
}

export default function AdminForm({
  data: preloadedData,
  onSubmit,
  allowPastStart
}) {

  const [ title, setTitle ] = useState('');
  const [ startTime, setStartTime ] = useState(new Date());
  const [ endTime, setEndTime ] = useState(getRecommendedEnd(new Date()));
  const [ calTopo, setCalTopo ] = useState('');
  const [ gpx, setGpx ] = useState('');
  const [ notes, setNotes ] = useState('');
  const [ status, setStatus ] = useState('normal');
  const [ shareUrl, setShareUrl ] = useState('');

  const [ completed, setCompleted ] = useState(null);

  const hasSetEnd = useRef(false);
  const queuedSubmit = useRef();

  const titleId = useId();
  const startId = useId();
  const endId = useId();
  const calTopoId = useId();
  const gpxId = useId();
  const notesId = useId();
  const statusId = useId();
  const shareId = useId();

  useEffect(() => {
    if (!preloadedData) {
      return;
    }

    hasSetEnd.current = true;
    setTitle(preloadedData.title || '');
    setStartTime(new Date(preloadedData.startTime));
    setEndTime(new Date(preloadedData.endTime));
    setCalTopo(preloadedData.calTopo || '');
    setGpx(preloadedData.gpx || '');
    setNotes(preloadedData.notes || '');
    setCompleted(preloadedData.completed || null);
    setStatus(preloadedData.status || 'normal');
    setShareUrl(`${window.location.protocol}//${window.location.host}/hike/${preloadedData.id}`);

  }, [preloadedData]);

  const makeData = () => {
    return {
      title,
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      calTopo,
      gpx,
      notes,
      status,
      completed
    }
  }

  const handleSubmit = (e) => {
    e?.preventDefault();
    onSubmit(makeData());
  }

  let preview = null;
  if (calTopo && !gpx) {
    // render caltopo
    preview = (
      <CalTopo url={calTopo} />
    );
  }
  else if (gpx) {
    preview = (
      <GPXViewer
        data={gpx} />
    );
  }

  const handleCompleted = () => {
    const newCompleted = completed
      ? null
      : new Date().toISOString();
    const newStatus = completed
      ? 'normal'
      : 'completed';

    setCompleted(newCompleted);
    setStatus(newStatus);
    
    queuedSubmit.current = true;
  };

  const markComplete = preloadedData
    ? (
      <button
        className={styles.Complete}
        onClick={(e) => {
          e.preventDefault();
          handleCompleted();
        }}>
          {
            completed
              ? 'Re-Activate'
              : 'Mark Complete'
          }
      </button>
    ) : null;

  useEffect(() => {
    if (queuedSubmit.current) {
      handleSubmit();
      queuedSubmit.current = false;
    }
  }, [queuedSubmit.current, completed]);

  const completeRow = completed
    ? (
      <div className={styles.Row}>
        Completed at {new Date(completed).toLocaleString()}.
      </div>
    ) : null;

  const statusRow = preloadedData
    ? (
      <Fragment>
        <div className={styles.Row}>
          <hr className={styles.Divider} />
        </div>
        <div className={styles.Row}>
          <label
            htmlFor={statusId}
            className={styles.Label}>
              Status
            </label>
          <select
            className={styles.StatusDropdown}
            id={statusId}
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
              if (e.target.value === 'completed') {
                setCompleted(new Date().toISOString());
              } else {
                setCompleted(null);
              }
            }}>
            {
              STATUS_TYPES.map((type) => (
                <option
                  key={type.value}
                  value={type.value}>
                    { type.label }
                  </option>
              ))
            }
          </select>
        </div>
      </Fragment>
    ) : null;

  const shareRow = preloadedData
    ? (
      <div className={styles.Row}>
        <label
          htmlFor={shareId}
          className={styles.Label}>
            CalTopo URL
          </label>
        <input
          id={shareId}
          className={styles.Text}
          type="text"
          readOnly={true}
          value={shareUrl} />
      </div>
    ) : null;

  const shareLink = async (e) => {
    e.preventDefault();
    if (navigator.share) {
      navigator.share({
        url: shareUrl
      });
    } else {
      try {
        await navigator.clipboard.writeText(shareUrl);
        alert('Copied URL!');
      } catch (err) {
        console.error('Could not copy');
      }
    }
  };

  const shareButton = preloadedData
    ? (
      <button
        className={styles.ShareButton}
        onClick={shareLink}>
        Share
      </button>
    ) : null;

  const duration = showDuration(startTime, endTime);
  return (
    <Fragment>
      <form
        className={styles.Form}
        onSubmit={handleSubmit}>

        <div className={styles.Row}>
          <label
            htmlFor={titleId}
            className={styles.Label}>
              Title
            </label>
          <input
            id={titleId}
            className={styles.Text}
            type="text"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }} />
        </div>

        <div className={styles.Row}>
          <label
            htmlFor={startId}
            className={styles.Label}>
              Start Time
            </label>
          <input
            id={startId}
            className={styles.DatePicker}
            type="datetime-local"
            min={
              allowPastStart
                ? null
                : dateToFormString(new Date())
            }
            value={dateToFormString(startTime)}
            onChange={(e) => {
              const newStart = new Date(e.target.value)
              setStartTime(newStart);

              if (!hasSetEnd.current) {
                setEndTime(getRecommendedEnd(newStart));
              }
            }}
            required />
        </div>

        <div className={styles.Row}>
          <label
            htmlFor={endId}
            className={styles.Label}>
              Return By
            </label>
          <input
            id={endId}
            className={styles.DatePicker}
            type="datetime-local"
            min={dateToFormString(startTime)}
            value={dateToFormString(endTime)}
            onChange={(e) => {
              setEndTime(new Date(e.target.value))
              hasSetEnd.current = true;
            }}
            required />
        </div>

        <div className={styles.Row}>
          <div className={styles.Label}>
            Duration
          </div>
          <div>
            { duration }
          </div>
        </div>

        <div className={styles.Row}>
          <label
            htmlFor={calTopoId}
            className={styles.Label}>
              CalTopo URL
            </label>
          <input
            id={calTopoId}
            className={styles.Text}
            type="text"
            value={calTopo}
            onChange={(e) => {
              setCalTopo(e.target.value);
            }} />
        </div>

        <div className={styles.Row}>
          <label
            htmlFor={gpxId}
            className={
              [styles.Label, styles.LabelTop].join(' ')
            }>
              GPX
            </label>
          <GPXUploader
            id={gpxId}
            textAreaClass={styles.TextArea}
            value={gpx}
            onChange={(value) => {
              setGpx(value);
            }} />
        </div>

        <div className={styles.Row}>
          <label
            htmlFor={notesId}
            className={
              [styles.Label, styles.LabelTop].join(' ')
            }>
              Notes
            </label>
          <textarea
            id={notesId}
            className={styles.TextArea}
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value);
            }} />
        </div>

        { statusRow }

        { completeRow }

        { shareRow }

        <div className={styles.Row}>
          <input
            className={styles.Submit}
            type="submit"
            value={
              preloadedData ? 'Save' : 'Create'
            } />

          { shareButton }
          { markComplete }
        </div>
      </form>
      <div className={styles.Preview}>
        {preview}
      </div>
    </Fragment>
  )
}

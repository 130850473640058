import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import PasswordGate from './components/PasswordGate.jsx';
import HomePage from './pages/Home.jsx';
import DetailPage from './pages/Detail.jsx';
import AdminEditPage from './pages/AdminEdit.jsx';
import AdminCreatePage from './pages/AdminCreate.jsx';
import { Logout } from './pages/Logout.jsx';
import './app.css';

const App = () => {
  const redirectHome = (<Navigate to="/" replace />)
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<HomePage />} />
            <Route path="hike" element={<PasswordGate />}>
              <Route index element={redirectHome} />
              <Route path=":hikeId" element={<DetailPage />} />
            </Route>
            <Route path="*" element={redirectHome} />
          </Route>
          <Route path="admin" element={<PasswordGate isAdmin={true} />}>
            <Route path="hike">
              <Route index element={<Navigate to="/admin/hikes" replace />} />
              <Route path=":hikeId" element={<AdminEditPage />} />
            </Route>
            <Route path="hikes" element={<AdminCreatePage />} />
          </Route>
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  )
};

export default App;

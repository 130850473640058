export function showDuration(start, end, {
  allowNegative,
  showSeconds
} = {}) {
  const units = [
    24 * 60 * 60, // days
    60 * 60, // hours
    60, // minutes
    1 // seconds
  ];
  const labels = ['day', 'hour', 'minute', 'second'];
  let totalSec = Math.ceil((end.getTime() - start.getTime()) / 1000);

  if (!allowNegative && totalSec < 0) {
    return 'Invalid duration';
  }
  else if (totalSec < 0) {
    totalSec = -1 * totalSec;
  }

  if (!showSeconds) {
    units.splice(units.length - 1, 1);
    labels.splice(labels.length - 1, 1);
  }

  let remaining = totalSec;
  const duration = units.map((unit) => {
    const unitCount = Math.floor(remaining / unit);
    remaining = remaining - (unitCount * unit);
    return unitCount;
  });

  return duration.reduce((out, unit, idx) => {
    if (unit === 0) {
      return out;
    }

    let element = `${unit} ${labels[idx]}`;
    if (unit > 1) {
      element += 's';
    }
    out.push(element);
    return out;
  }, []).join(' ');
}

import { useState, useRef, useEffect } from 'react';
import { gpx as gpxParser } from '@tmcw/togeojson';
import * as styles from './GPXUploader.module.css';

export default function GPXUploader({
  value,
  onChange,
  textAreaClass,
  fileClass,
  id
}) {
  const [ showUploader, setShowUploader ] = useState(!!value);
  const fileRef = useRef();

  const readFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async (readEvent) => {
      const gpxContent = readEvent.target.result;
      // convert to GeoJSON
      const geoJson = gpxParser(
        new DOMParser().parseFromString(gpxContent, 'text/xml')
      );

      setShowUploader(false);
      onChange(JSON.stringify(geoJson));
    };
    reader.readAsText(file);
  }

  useEffect(() => {
    if (fileRef.current) {
      fileRef.current.addEventListener('change', readFile);

      return () => fileRef.current?.removeEventListener('change', readFile);
    }
  }, [fileRef.current]);

  if (!showUploader && value) {
    return (
      <div className={styles.Row}>
        <div>
          File uploaded.
        </div>        
        <button
          className={styles.ChangeFile}
          onClick={(e) => {
            e.preventDefault();
            setShowUploader(true);
          }}>
          Change File
        </button>

        <button
          className={styles.ChangeFile}
          onClick={(e) => {
            e.preventDefault();
            onChange('');
            setShowUploader(true);
          }}>
          Clear
        </button>
      </div>
    );
  }

  return (
    <input
      type="file"
      id={id} 
      className={fileClass}
      accept=".gpx"
      ref={fileRef} />
  )
}
import { useEffect, useState } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import { tokenState, accountTypeState, isAuthed } from '../atoms/authState';

import Login from './Login';

const { API_HOST } = process.env;

function parseToken(token) {
  const elements = token.split('.');
  const rawPayload = elements[1];
  return JSON.parse(atob(rawPayload));
}

function PasswordGate({ isAdmin }) {
  const [ error, setError ] = useState();
  const setToken = useSetRecoilState(tokenState);
  const setAccountType = useSetRecoilState(accountTypeState);
  const isLoggedIn = useRecoilValue(isAuthed);

  const storedCookie = Cookies.get('token');

  const performLogin = async (password) => {
    try {
      const res = await fetch(`${API_HOST}/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password
        })
      });
      const data = await res.json();
      if (res.status !== 200) {
        throw new Error(data.error || 'Login failed');
      }

      completeLogin(data.token, true);
    } catch (err) {
      console.error(err)
      setError(err.message || 'Login failed');
    }
      
  };

  const completeLogin = (token, shouldWriteCookie) => {
    try {
      if (!token) {
        throw new Error('Login failed');
      }

      const payload = parseToken(token);
      let accountType = 'default';
      if (payload.isAdmin) {
        accountType = 'admin';
      }
      else if (payload.isDemo) {
        accountType = 'demo';
      }

      if (isAdmin && !payload.isAdmin) {
        throw new Error('Admin permissions required!');
      }

      setToken(token);
      setAccountType(accountType);

      if (shouldWriteCookie) {
        // save the cookie for 2 hours
        Cookies.set('token', token, {
          expires: new Date(Date.now() + (1.75 * 60 * 60 * 1000))
        });
      }
    } catch (err) {
      console.error(err)
      Cookies.remove('token');
      setError(err.message || 'Login failed');
    }
  }

  useEffect(() => {
    // autologin if we have a cookie
    if (storedCookie) {
      // but don't update the cookie again or it will get out of date
      completeLogin(storedCookie, false);
    }
  }, [storedCookie]);

  if (!isLoggedIn) {
    return (
      <Login
        login={performLogin}
        error={error} />
    );
  }

  return (
    <div>
      <Outlet />
    </div>
  );
}

export default PasswordGate;
